/*eslint-disable*/
import React, { useState } from "react"

import { Link } from "gatsby"

import ParallaxSection from "components/ParallaxSection"
// nodejs library that concatenates classes
import classNames from "classnames"
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart"
import Subject from "@material-ui/icons/Subject"
// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"
import CardAvatar from "components/Card/CardAvatar.js"
import Accordion from "components/Accordion/Accordion.js"
import HeroSection from "components/HeroSection"
import LinearProgress from "@material-ui/core/LinearProgress"
import Typography from "@material-ui/core/Typography"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"

// import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js"
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js"
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js"

import { Router } from "@reach/router"

import { gql, useQuery } from "@apollo/client"

import SEO from "../components/Seo"
import useSiteMetadata from "../hooks/use-site-metadata"

import PageLayout from "../components/PageLayout"

// const useStyles = makeStyles(styles)
const useStyles = makeStyles(productStyle)

const PortfolioPage = ({location}) => {
  // var filter_tag = "all"
  const [filterTag, setFilterTag] = useState('"ολα"')
  const { cmsURL, baseImageURL } = useSiteMetadata()


  const PROJECTS_QUERY = gql`
    {
      projects: projectsCollection(sort:{order:1 },filter: { published: true${
        filterTag == '"ολα"' ? "" : ", tags: " + filterTag
      } }) {
        _id
        _created
        image {
          path
        }
        tags
        title
        title_slug
        excerpt
        gallery {
          path
          meta
        }
      }
      tags: projectsCollection {
        tags
      }
    }
  `


  // const TAGS_QUERY = gql`
  //   {
  //     projectsCollection {
  //       tags
  //     }
  //   }
  // `

  const classes = useStyles()

  const { loading, error, data, fetchMore } = useQuery(PROJECTS_QUERY)

  if (loading) return <LinearProgress />
  if (error) return `Error! ${error.message}`

  if (!data.projects) {
    data.projects = []
  }


  const siteURL = "/project/"

  var all_tags = data.tags
    .map(entry => {
      return entry.tags
    })
    .reduce((previous, current) => [...previous, ...current])


  all_tags = [
    "νέο",
    "ανακαίνιση",
    "οικία",
    "επαγγελματικό",
    "τεχνικές_εφαρμογές"
  ]

  all_tags.unshift("ολα")

  const tags = Array.from(new Set(all_tags)).map(tag => (
    <a href={"#"+tag}>
      #
      <span onClick={() => setFilterTag('"' + event.target.textContent + '"')}>
        {tag}
      </span>
    </a>
  ))

  const projects = data.projects.map(project => {
    const featured_image_url = project.image
      ? baseImageURL + project.image.path
      : baseImageURL + project.gallery[0].path
      // ? cmsURL + "storage/uploads" + project.image.path
      // : cmsURL + project.gallery[0].path
    const tag_list = project.tags.map(tag => <a href={`#${tag}`} style={{color:'white'}}>#{tag}&nbsp;</a>)
    // const tag_list=[]
    return (
      // <a href={siteURL+project.title_slug}>{project.title}</a>
      <GridItem xs={12} sm={6} md={6}>
        <Card
          background
          style={{ backgroundImage: `url(${featured_image_url})` }}
        >
          <CardBody background>
          <div style={{backgroundColor:'rgba(0, 0, 0, 0.8)', padding:'0.5rem 0 0.5rem 0', opacity:'0.5'}}>
            <h6 className={classes.cardCategoryWhite} style={{color:'white'}}>{tag_list}</h6>
            </div>
            {/* <h6 className={classes.cardCategoryWhite}>{tag_list}</h6> */}
            {/* <Router basepath="/project/"> */}

            {/* <a path={project.title_slug} href={siteURL + project.title_slug}> */}
            <Link to={siteURL + project.title_slug}>
              <h3 className={classes.cardTitleWhite}>{project.title}</h3>
              {/* </a> */}
            </Link>

            {/* </Router> */}
            <p className={classes.cardDescriptionWhite}>{project.excerpt}</p>
            {/* <Button round color="danger">
                        <Subject /> <a href={siteURL+project.title_slug}>Read Article</a>
                      </Button> */}
          </CardBody>
        </Card>
      </GridItem>
    )
  })

  // const images = data.projectsCollection[0].gallery.map(image => ({original: cmsURL+image.path, thumbnail: cmsURL+image.path}))
  // const header_image = cmsURL+"storage/uploads/2021/03/04/6040ecbbbe6f0103.jpg"
  const header_image = baseImageURL+"/2021/03/04/6040ecbbbe6f0103.jpg"

  return (
    <div className={classes.productPage}>
      <PageLayout>
      <SEO
          title="Έργα"
          description="Τα έργα μας"
          image={header_image}
          pathname={location.pathname}
        />
        {/* <HeroSection
          image={
            "https://images.unsplash.com/photo-1575971637203-d6255d9947a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80"
          }
        /> */}
        <ParallaxSection
          image={header_image}
          filter="dark"
          className={classes.pageHeader}
        />

        {/* <div className={classNames(classes.main, classes.mainRaised)}> */}
        <div className={classNames(classes.section, classes.sectionGray)}>
          <div className={classes.container}>
            {/* <div className={classes.title}>
              <h3>Έργα</h3>
            </div> */}
            <div className={classNames(classes.main, classes.mainRaised)}>
              <Breadcrumbs
                aria-label="breadcrumb"
                style={{ marginBottom: "2rem" }}
              >
                <Link color="inherit" to="/">
                  Αρχική
                </Link>
                <Link color="inherit" to="/projects">
                  Έργα
                </Link>
                <Typography color="textPrimary">
                  #{filterTag.replace(/"/g, "")}
                </Typography>
              </Breadcrumbs>
              {/* <GridItem  xs={12} sm={12} md={12}>{tags}</GridItem> */}
              <GridContainer>
              {tags}</GridContainer>
              <GridContainer>{projects}</GridContainer>

            </div>
          </div>
        </div>
        {/* </div> */}
      </PageLayout>
    </div>
  )
}

export default PortfolioPage
